/**
 * Get the rendered width and height of an <img>
 * source: https://stackoverflow.com/questions/70785723/how-to-calculate-the-rendered-dimensions-of-an-image-styled-with-object-fit-con
 * @param image - Image element
 */
export const getImageDimensions = (image: HTMLImageElement) => {
    const {
        width: cw,
        height: ch,
        naturalWidth: nw,
        naturalHeight: nh,
    } = image;
    const nAR = nw / nh;
    const cAR = cw / ch;
    const [width, height] = nAR >= cAR ? [cw, cw / nAR] : [ch * nAR, ch];
    return { width, height };
};
