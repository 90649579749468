import { FC, HTMLAttributes } from 'react';
import { useSnapshot } from 'reactish-state';
import { PopupError as PopupErrorComponent } from '../../components/PopupError';
import { store } from '../../stores';

export const PopupError: FC<HTMLAttributes<HTMLDivElement>> = (props) => {
    const { error } = useSnapshot(store);

    if (!error) {
        return null;
    }

    return <PopupErrorComponent {...props} error={error} />;
};
