import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AccountField } from '../AccountField';
import { CloseIcon } from '../CloseIcon';
import { PayTableDraw } from '../PayTableDraw';
import { SoundIcon } from '../SoundIcon';
import {
    Container,
    HeaderAccountContainer,
    HeaderDetailsContainer,
    HeaderDetailsLabel,
} from './styled';

export type HeaderProps = HTMLAttributes<HTMLDivElement> & {
    gameId: string;
    bank: string;
    bet: string;
    win: string;
    isPayTableOpen?: boolean;
    onTogglePayTable?: (isOpen: boolean) => unknown;
    topOverlay?: boolean;
};

export const HeaderCloseIcon = styled(CloseIcon)`
    align-self: flex-end;
`;

export const HeaderAccountField = styled(AccountField)`
    flex: 1;
`;

export const HeaderPayTableDraw = styled(PayTableDraw)``;

export const Header: FC<HeaderProps> = ({
    children,
    isPayTableOpen,
    onTogglePayTable,
    ...props
}) => {
    const { t } = useTranslation();

    return (
        <Container {...props}>
            <HeaderDetailsContainer>
                <SoundIcon />
                <HeaderDetailsLabel>
                    {t('header.gameId')}: {props.gameId}
                </HeaderDetailsLabel>
                <HeaderCloseIcon />
            </HeaderDetailsContainer>
            <HeaderAccountContainer>
                <HeaderAccountField type="bank">
                    {props.bank}
                </HeaderAccountField>
                <HeaderAccountField type="bet">{props.bet}</HeaderAccountField>
                <HeaderAccountField type="win">{props.win}</HeaderAccountField>
            </HeaderAccountContainer>
            <HeaderPayTableDraw
                isOpen={isPayTableOpen}
                onTogglePayTable={onTogglePayTable}
            />
        </Container>
    );
};
