import { SiteApiCurrency } from '@parlaygames/site-api';
import { DefaultTheme } from 'styled-components';
import { createState } from '../lib/create-state';
import { getSpinOffsets } from '../lib/spin';
import defaultTheme from '../theme';
import { Payout } from '../types/api';

export type FetcherState = 'undefined' | 'loading' | 'complete';

export type ReelSpin = 'spin' | 'stop' | 'slide-up';

export type StoreState = {
    style: string;
    gameId: number;
    sessionId: number;
    round: number;

    maxBet: number;
    configPayLines: number;

    denominations: number[];
    denominationIndex: number;

    payLines: number;
    betPerLine: number;

    bonus: number;
    cash: number;
    value: number;

    winnings: number[];
    payouts: Payout[];

    won: boolean;

    currency: SiteApiCurrency;

    wagerType: string;
    betKey: string;

    reels: {
        wheels: string;
        spins: ReelSpin[];
        blinks: boolean[];
        wins: number[];
        highlights: Array<'true' | 'false'>;
        overlays: boolean[];
        shifts: number[];
        spinOffsets: number[];
        suspense: boolean;
    };

    preloading: boolean;
    themeLoading: boolean;

    theme: DefaultTheme;

    isPayTableAnimating: boolean;
    isPayTableOpen: boolean;

    sfx: number[];

    error: string;

    aborters: Record<string, AbortController>;

    fetchInitialize: FetcherState;
    fetchBet: FetcherState;
};

export const defaultState = {
    style: '',
    gameId: 0,
    sessionId: 0,
    round: 0,

    maxBet: 0,
    configPayLines: 0,
    denominationIndex: -1,
    denominations: [],

    payLines: 0,
    betPerLine: 0,

    bonus: 0,
    cash: 0,
    value: 0,

    winnings: [],
    payouts: [],

    currency: {
        centsPos: '',
        centsSym: '',
        decimalPrec: 0,
        pos: '',
        sep: '',
        symbol: '',
        thousandSep: '',
    },

    wagerType: '',
    betKey: '',

    won: false,

    reels: {
        wheels: '',
        wins: [],
        blinks: [false, false, false],
        spins: ['stop', 'stop', 'stop'],
        highlights: [],
        overlays: [false, false, false],
        shifts: [0, 0, 0],
        spinOffsets: getSpinOffsets(3, 6),
        suspense: false,
    },

    preloading: true,
    themeLoading: true,
    theme: defaultTheme,

    isPayTableAnimating: false,
    isPayTableOpen: false,

    sfx: [],

    error: '',

    aborters: {},

    fetchInitialize: 'undefined',
    fetchBet: 'undefined',
} as StoreState;

export const store = createState(defaultState);
